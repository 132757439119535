import React from "react";
import Tooltip from "../../../../components/Tooltip/Tooltip";
import Icon, {IconType} from "../../../../components/Icon/Icon";
import {Modal, useModal} from "pulse-modal";
import YearlyAbsenceForm from "./YearlyAbsenceForm";
import {UserAbsenceConfigYear} from "../../../../api/staff/code";

function EditYearConfigTableAction({yearConfig, tooltipText}: Props) {
    const {isShown, toggle} = useModal();
    return (
        <React.Fragment>
            <div className="icon-tooltip-wrapper-md mr-2">
                <Tooltip tooltipText={tooltipText} size={"md"} place={"left"}>
                    <Icon
                        rootElement={"Div"}
                        icon={IconType.Edit}
                        size={"Medium"}
                        className="icon-dark"
                        onClick={toggle}
                    />
                </Tooltip>
            </div>
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Yearly Entitlement"}
                modalSize={"sm"}
                bodyChildren={
                    <React.Fragment>
                        <YearlyAbsenceForm
                            yearConfig={yearConfig}
                            onClose={toggle}
                            isNewConfig={false}
                        />
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
}

export default EditYearConfigTableAction;

interface Props {
    yearConfig: UserAbsenceConfigYear;
    tooltipText: string;
}
