import {useState} from "react";
import {showErrorToast, showSuccessToast} from "../../../utils/toastUtils";
import {useEditUserAbsenceConfigForm} from "./useEditUserAbsenceConfigForm";
import {UserAbsenceConfigDefault} from "../../../api/staff/code";
import {
    useAppDispatch,
    useAppSelector,
    useAsyncStoreDispatch
} from "../../../components/Hooks/redux";
import {saveUserDefaultConfig} from "../../../store/userAbsenceDefaultConfig/thunks/thunks";
import {getUserAbsenceConfig} from "../../../store/userAbsenceConfig/thunks/thunks";
import {nullifyUserDefaultConfigStore} from "../../../store/userAbsenceDefaultConfig/userAbsenceDefaultConfigSlice";

export function useDefaultConfig(initialConfig: UserAbsenceConfigDefault) {
    const [config, setConfig] = useState<UserAbsenceConfigDefault>(initialConfig);
    const dispatch = useAppDispatch();
    const {fullName} = useEditUserAbsenceConfigForm();
    const userDefaultConfigStore = useAppSelector((state) => state.userAbsenceDefaultConfig);
    const {handleNonNullableAsyncStoreDispatch} = useAsyncStoreDispatch();

    function updateConfig(entity: UserAbsenceConfigDefault) {
        setConfig(entity);
    }

    async function saveDefaultConfig() {
        const success: boolean = await handleNonNullableAsyncStoreDispatch(
            saveUserDefaultConfig(config)
        );

        if (!success) {
            showErrorToast("Could not save user default configuration");
            return;
        }

        showSuccessToast(`Saved default configuration for ${fullName}`);

        dispatch(getUserAbsenceConfig(config.username));
        dispatch(nullifyUserDefaultConfigStore());
    }

    return {
        config,
        updateConfig,
        saveDefaultConfig,
        userDefaultConfigStore
    };
}
