import React from "react";
import {useEditRegionContainer} from "./Hooks/useEditRegionContainer";
import {WithServiceState} from "store-fetch-wrappers";
import EditRegionForm from "./Components/EditRegionForm";
import TopBar from "../../Layouts/Layout/Components/TopBar/TopBar";

const ServiceWrapper = WithServiceState(EditRegionForm);

function RegionContainer() {
    const {regionStore} = useEditRegionContainer();
    return (
        <>
            <TopBar titleText="Location" />
            <div className="page-container pt-4">
                <ServiceWrapper
                    loading={regionStore.loading}
                    error={regionStore.error}
                    data={regionStore.data}
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                />
            </div>
        </>
    );
}

export default RegionContainer;
