import {showErrorToast, showSuccessToast} from "../../../utils/toastUtils";
import {useEditUserAbsenceConfigForm} from "./useEditUserAbsenceConfigForm";
import {useEffect, useState} from "react";
import {UserAbsenceConfigYear} from "../../../api/staff/code";
import {
    useAppDispatch,
    useAppSelector,
    useAsyncStoreDispatch
} from "../../../components/Hooks/redux";
import {getUserAbsenceConfig} from "../../../store/userAbsenceConfig/thunks/thunks";
import {nullifyUserYearConfigStore} from "../../../store/userAbsenceYearConfig/userAbsenceYearConfigSlice";
import {saveUserYearConfig} from "../../../store/userAbsenceYearConfig/thunks/thunks";

export function useYearlyAbsenceForm(props: UserAbsenceConfigYear) {
    const [config, setConfig] = useState<UserAbsenceConfigYear>(props);
    const dispatch = useAppDispatch();
    const {fullName} = useEditUserAbsenceConfigForm();
    const userYearlyConfigStore = useAppSelector((state) => state.userAbsenceYearConfig);
    const userHolidayConfigStore = useAppSelector((state) => state.userAbsenceConfig);
    const {handleNonNullableAsyncStoreDispatch} = useAsyncStoreDispatch();

    useEffect(() => {
        setConfig(props);
    }, [props]);

    function updateConfig(entity: UserAbsenceConfigYear) {
        setConfig(entity);
    }

    async function saveYearlyConfig(isNewConfig: boolean): Promise<boolean> {
        const yearsConfigs: Array<UserAbsenceConfigYear> = userHolidayConfigStore.data?.years || [];

        if (isNewConfig) {
            const isValid = validateNewConfig(yearsConfigs);

            if (!isValid) {
                showErrorToast("A config already exists for this year. Edit existing config");
                return false;
            }
        }

        const success: boolean = await handleNonNullableAsyncStoreDispatch(
            saveUserYearConfig(config)
        );

        if (!success) {
            showErrorToast(`Could not save ${config.year} yearly entitlement for user.`);
            return false;
        }

        showSuccessToast(`Saved ${config.year} annual leave configuration for ${fullName}`);

        dispatch(getUserAbsenceConfig(config.username));
        dispatch(nullifyUserYearConfigStore());

        return true;
    }

    function validateNewConfig(yearsConfigs: Array<UserAbsenceConfigYear>): boolean {
        if (yearsConfigs.length < 1) return true;

        const index = yearsConfigs.findIndex((item) => item.year === config.year);

        return index < 0;
    }

    return {
        saveYearlyConfig,
        userYearlyConfigStore,
        config,
        updateConfig
    };
}
