import React from "react";
import FormActionContainer from "../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import {Modal, useModal} from "pulse-modal";
import {showErrorToast, showSuccessToast} from "../../../utils/toastUtils";
import ActionButtonConfirmation, {IconButton} from "../../Button/ActionButtonConfirmation";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../Navigation/routeNames";
import SMSStaffListButton from "./SMSStaffListButton";
import {useAppSelector, useAsyncStoreDispatch} from "../../Hooks/redux";
import {sendMassSms, SMSFormDetails} from "../../../store/sms/thunks/thunks";

const SMSActions = () => {
    const history = useHistory();
    const {toggle, isShown} = useModal();
    const smsStore = useAppSelector(
        (state) => state.sms.data || {staffMembers: [], bodyText: "", headerText: ""}
    );
    const {handleNonNullableAsyncStoreDispatch} = useAsyncStoreDispatch();

    const sendSMS = async () => {
        const success: boolean = await handleNonNullableAsyncStoreDispatch(sendMassSms(smsStore));

        if (!success) {
            showErrorToast("Could not send bulk SMS.");
            return;
        }
        toggle();
        showSuccessToast(`SMS message sent to ${smsStore.staffMembers.length} staff member(s).`);

        goToStaffDatabase();
    };

    const goToStaffDatabase = () => {
        history.push(routeNames.staffDatabase.path);
    };

    return (
        <React.Fragment>
            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Send"}
                    onClick={() => {
                        const valid = validateSMS(smsStore);
                        if (!valid) return;
                        toggle();
                    }}
                    colour={ButtonColourOptions.Yellow}
                    roundedCorner
                />
                <ActionButtonConfirmation
                    action={() => {
                        goToStaffDatabase();
                        showSuccessToast("Cancelled SMS");
                    }}
                    modalText={"Are you sure you want to cancel this SMS?"}
                    modalTitle={"Cancel SMS"}
                    buttonText={"Cancel"}
                    buttonColour={ButtonColourOptions.DarkBlue}
                    iconButton={IconButton.Button}
                />
                <SMSStaffListButton />
            </FormActionContainer>
            <Modal
                modalSize={"sm"}
                title={"SMS Confirmation"}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p className="mb-0">Are you sure you want to sent this SMS?</p>
                    </div>
                }
                onClose={toggle}
                isShown={isShown}
                footerChildren={
                    <div className="ml-0 mr-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={sendSMS}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Cancel"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default SMSActions;

function validateSMS(sms: SMSFormDetails): boolean {
    if (sms.staffMembers.length === 0) {
        showErrorToast("Please select a group to send an SMS to.");
        return false;
    }

    if (sms.bodyText.length === 0) {
        showErrorToast("Please enter an SMS message.");
        return false;
    }

    if (sms.bodyText.length > 154) {
        showErrorToast(
            "The SMS message is too long. Please ensure the message is 154 characters or less."
        );
        return false;
    }

    return true;
}
