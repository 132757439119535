import React, {ReactNode} from "react";
import {HiMenuAlt2} from "react-icons/hi";
import TopBarTitle from "./TopBarTitle";
import PageTitleTag from "../PageTitleTag";
import {setSidebarToggled} from "../../../../../store/sidebar/sidebarSlice";
import {useAppDispatch, useAppSelector} from "../../../../../components/Hooks/redux";

export interface TopBarProps {
    titleText: string;
    leftElem?: ReactNode;
    middleElem?: ReactNode;
    rightElem?: ReactNode;
}

export default function TopBar(props: TopBarProps) {
    const sidebarOpen = useAppSelector((state) => state.sidebar.data) || false;
    const dispatch = useAppDispatch();
    const title = props.leftElem ? props.leftElem : <TopBarTitle title={props.titleText} />;
    const rightElem = props.rightElem;

    return (
        <section className="top-bar">
            <PageTitleTag title={props.titleText} />

            {/* LEFT AREA */}
            <div className="left">
                <div className="hamburger-wrapper">
                    <button type="button" onClick={() => dispatch(setSidebarToggled(!sidebarOpen))}>
                        <HiMenuAlt2 size={24} />
                    </button>
                </div>

                {title}
            </div>

            {/* MIDDLE AREA */}
            <div className="middle">{props.middleElem}</div>

            {/* RIGHT AREA */}
            <div className="right">{rightElem}</div>
        </section>
    );
}
