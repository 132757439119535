import React, {useEffect, useState} from "react";
import {RequestFilterProps} from "../../../components/Filters/helpers/filterHelpers";
import {DebounceInput} from "react-debounce-input";
import GenericTypeDropdown from "../../../components/Dropdown/GenericTypeDropdown";
import {OptionsFromType} from "../../../components/Dropdown/Helpers/dropdownUtils";
import {useHistory} from "react-router-dom";
import {useQuery} from "../../../components/Hooks/useQuery";
import RegionSingleDropdown from "../../../components/Dropdown/RegionSingleDropdown";
import {ClinicalGrade} from "../../../api/staff/code";

const StaffDatabaseFilters = (props: RequestFilterProps<StaffDatabaseRequest>) => {
    const history = useHistory();
    const query = useQuery();
    const [state, setState] = useState<StaffDatabaseRequest>({});

    useEffect(() => {
        const {nameTelephoneSearch, gradeSearch, regionSearch} = StaffDatabaseQuery;
        const nameTelephone = query.get(nameTelephoneSearch);
        const currentGrade = query.get(gradeSearch);
        const currentRegion = query.get(regionSearch);

        updateState({
            clinicalGrade: getClinicalGrade(currentGrade),
            nameTelephone: nameTelephone || undefined,
            region: currentRegion ? +currentRegion : undefined
        });
    }, []);

    function updateState(newRequest: StaffDatabaseRequest) {
        setState(newRequest);

        props.onRequestChanged(newRequest);
        buildUrlHistory(newRequest);
    }

    const buildUrlHistory = (request: StaffDatabaseRequest) => {
        const {nameTelephone, clinicalGrade, region} = request;
        const {nameTelephoneSearch, gradeSearch, regionSearch} = StaffDatabaseQuery;
        const queryStrings: string[] = [];
        if (nameTelephone && nameTelephone?.length > 0) {
            queryStrings.push(`${nameTelephoneSearch}=${nameTelephone}`);
        }

        if (region) {
            queryStrings.push(`${regionSearch}=${region}`);
        }

        queryStrings.push(`${gradeSearch}=${clinicalGrade}`);

        history.push({
            search: `?${queryStrings.join("&")}`
        });
    };

    return (
        <React.Fragment>
            <div className="filter-inner-container-wrapper">
                <div className="filter-item">
                    <h6>Search staff name / phone number</h6>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields"}
                        onChange={(event) => {
                            const {value} = event.target;
                            updateState({
                                ...state,
                                nameTelephone: value
                            });
                        }}
                        disabled={false}
                        value={state.nameTelephone}
                        placeholder="Search staff database..."
                    />
                </div>
                <div className="filter-item">
                    <h6>Clinical Grade</h6>
                    {state.clinicalGrade && (
                        <GenericTypeDropdown
                            name="clinical-grade-dropdown"
                            enumOptions={uiFilterFriendlyClinicalGrades}
                            splitByCapitalLetter={true}
                            getOptionsFrom={OptionsFromType.Value}
                            clearable={false}
                            searchable={false}
                            changeOption={(grade) => {
                                if (!grade) return;
                                updateState({
                                    ...state,
                                    clinicalGrade: grade.toString()
                                });
                            }}
                            id={state.clinicalGrade}
                            disabled={false}
                            searchWithDecapitalisedFirstLetter={false}
                        />
                    )}
                </div>
                <div className="filter-item">
                    <h6>Location</h6>
                    <RegionSingleDropdown
                        onChange={(region) => {
                            updateState({
                                ...state,
                                region: region?.id
                            });
                        }}
                        regionId={state.region}
                        clearable
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default StaffDatabaseFilters;

export interface StaffDatabaseRequest {
    nameTelephone?: string;
    clinicalGrade?: string;
    region?: number;
}

function setValueFromInput(
    event: React.ChangeEvent<HTMLInputElement>,
    setAction: React.Dispatch<React.SetStateAction<string>>
): void {
    const {value} = event.target;
    setAction(value);
}

function getClinicalGrade(value: string | null): uiFilterFriendlyClinicalGrades {
    if (!value) return uiFilterFriendlyClinicalGrades.All;

    if (value === ClinicalGrade.EAC) {
        return uiFilterFriendlyClinicalGrades.EAC;
    }

    return uiFilterFriendlyClinicalGrades[value as keyof typeof uiFilterFriendlyClinicalGrades];
}

// eslint-disable-next-line no-shadow
export enum uiFilterFriendlyClinicalGrades {
    Paramedic = "Paramedic",
    Technician = "Technician",
    FirstResponder = "FirstResponder",
    EAC = "Emergency Ambulance Crew",
    Nurse = "Nurse",
    All = "All"
}

export const StaffDatabaseQuery = {
    nameTelephoneSearch: "nameTelephoneSearch",
    gradeSearch: "grade",
    regionSearch: "region"
};
