import React, {useEffect} from "react";
import {routeNames} from "../../components/Navigation/routeNames";
import {WithServiceState} from "store-fetch-wrappers";
import {useParams} from "react-router-dom";
import StaffForm from "../../components/StaffForm/StaffForm";
import {NavigationItem, setNavigationItems} from "../../store/navigation/navigationSlice";
import {fetchStaffMember} from "../../store/staff/thunks/thunks";
import {useAppDispatch, useAppSelector} from "../../components/Hooks/redux";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const StaffServiceWrapper = WithServiceState(StaffForm);

const EditStaffMember = () => {
    const dispatch = useAppDispatch();
    const staffStore = useAppSelector((state) => state.staff);
    const params: any = useParams();

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.staffDatabase.name,
                path: routeNames.staffDatabase.path
            },
            {
                name: routeNames.staffMessengerLogs.name,
                path: routeNames.staffMessengerLogs.path
            },
            {
                name: routeNames.regionList.name,
                path: routeNames.regionList.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));

        dispatch(fetchStaffMember(params.username));
    }, []);

    return (
        <React.Fragment>
            <TopBar titleText="Edit Staff Member" />
            <div className="page-container pt-4">
                <StaffServiceWrapper
                    loading={staffStore.loading}
                    error={staffStore.error}
                    placeholderBlockCount={1}
                    data={staffStore.data}
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                />
            </div>
        </React.Fragment>
    );
};

export default EditStaffMember;
