import React, {useEffect, useState} from "react";
import {DDProps} from "../../Dropdown/Helpers/dropdownUtils";
import Select, {MultiValue} from "react-select";
import {
    getSmsFiltersFromStaffList,
    getStaffMembersFromSelectedTags,
    processTag
} from "../Helpers/smsHelpers";
import FormRowWithTooltip from "../../Form/FormRowWithTooltip";
import {IconType} from "../../Icon/Icon";
import {UserData} from "../../../api/staff/code";
import {setSMSStaffMembers} from "../../../store/sms/smsSlice";
import {useAppDispatch, useAppSelector} from "../../Hooks/redux";

const SMSFilters = () => {
    const staffList = useAppSelector((state) => state.staffList.data || []);
    const [filterOptions, setFilterOptions] = useState<DDProps[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<MultiValue<DDProps>>([]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        processStaffListIncoming(staffList);
    }, []);

    useEffect(() => {
        processStaffListIncoming(staffList);
    }, [staffList]);

    const processStaffListIncoming = (incomingStaffList: UserData[]) => {
        const tags = getSmsFiltersFromStaffList(incomingStaffList);

        const options = sortTagsToDropdownProps(tags);

        setFilterOptions(options);
    };

    const handleFiltersSelected = (newValue: MultiValue<DDProps>) => {
        if (staffList.length === 0) return;
        setSelectedOptions(newValue);

        const selectedFilters = getTagsFromSelectedOptions(newValue);
        dispatch(setSMSStaffMembers(getStaffMembersFromSelectedTags(selectedFilters, staffList)));
    };

    const getTagsFromSelectedOptions = (options: MultiValue<DDProps>): string[] => {
        if (!options) {
            return [];
        }
        if (options.length === 0) {
            return [];
        }

        return options.map((opt) => opt.value.toString());
    };

    return (
        <React.Fragment>
            <FormRowWithTooltip
                tooltipText={`When one audience is selected, everyone in that audience will be sent the message. When two (or more) audiences are selected, only staff that have are assigned to both audience groups will be sent the message.`}
                icon={IconType.Question}
                rowName={"Filters"}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <Select
                    className="search-bar"
                    classNamePrefix="select-options"
                    options={filterOptions}
                    onChange={handleFiltersSelected}
                    isSearchable={false}
                    value={selectedOptions}
                    isMulti={true}
                    noOptionsMessage={() => "No Filters Found"}
                />
            </FormRowWithTooltip>
        </React.Fragment>
    );
};

export default SMSFilters;

export const sortTagsToDropdownProps = (tags: string[]): DDProps[] => {
    return tags.map((tag) => {
        return {
            label: processTag(tag),
            value: tag
        };
    });
};
