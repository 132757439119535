import {useEffect, useState} from "react";
import {useEditUserAbsenceConfigForm} from "../../../Hooks/useEditUserAbsenceConfigForm";
import {UserAbsenceConfigKey, UserAbsenceConfigYear} from "../../../../../api/staff/code";
import {toArray} from "../../../../../utils/sortingUtils";
import {useAppDispatch, useAsyncStoreDispatch} from "../../../../../components/Hooks/redux";
import {showErrorToast, showSuccessToast} from "../../../../../utils/toastUtils";
import {deleteUserYearConfig} from "../../../../../store/userAbsenceYearConfig/thunks/thunks";
import {getUserAbsenceConfig} from "../../../../../store/userAbsenceConfig/thunks/thunks";
import {nullifyUserYearConfigStore} from "../../../../../store/userAbsenceYearConfig/userAbsenceYearConfigSlice";

function useYearlyConfigTable(props: UserAbsenceConfigYear[]) {
    const [rows, setRows] = useState<TableRow<UserAbsenceConfigYear>[]>([]);
    const dispatch = useAppDispatch();
    const {fullName} = useEditUserAbsenceConfigForm();
    const {handleNonNullableAsyncStoreDispatch} = useAsyncStoreDispatch();

    useEffect(() => {
        setRows(toTableRow(toArray(props)));
    }, [props]);

    async function deleteYearlyConfig(yearConfig: UserAbsenceConfigYear) {
        const request: UserAbsenceConfigKey = {
            year: yearConfig.year,
            username: yearConfig.username
        };

        const success: boolean = await handleNonNullableAsyncStoreDispatch(
            deleteUserYearConfig(request)
        );

        if (!success) {
            showErrorToast(`Could not delete ${yearConfig.year} yearly entitlement for user.`);
            return;
        }

        showSuccessToast(`Deleted yearly entitlement for ${fullName}`);

        dispatch(getUserAbsenceConfig(yearConfig.username));
        dispatch(nullifyUserYearConfigStore());
    }

    function toTableRow(entity: UserAbsenceConfigYear[]): TableRow<UserAbsenceConfigYear>[] {
        return entity.map((item) => {
            return {
                year: item.year,
                holidayLimit: item.holidayLimit,
                actions: item
            };
        });
    }

    return {
        rows,
        deleteYearlyConfig
    };
}

export default useYearlyConfigTable;

interface TableRow<T> {
    year: number;
    holidayLimit: number;
    actions: T;
}
