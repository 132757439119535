import React, {useEffect} from "react";
import {routeNames} from "../../components/Navigation/routeNames";
import StaffMessengerLogsTable from "./Components/StaffMessengerLogsTable";
import {WithServiceState} from "store-fetch-wrappers";
import {NavigationItem, setNavigationItems} from "../../store/navigation/navigationSlice";
import {getDataForSmsLogFiltersWithBlankTable} from "../../store/smsLogs/thunks/thunks";
import {useAppDispatch, useAppSelector} from "../../components/Hooks/redux";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const ServiceWrapper = WithServiceState(StaffMessengerLogsTable);

function StaffMessengerLogs(): JSX.Element {
    const dispatch = useAppDispatch();
    const smsLogsStore = useAppSelector((state) => state.smsLogs);
    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.staffDatabase.name,
                path: routeNames.staffDatabase.path
            },
            {
                name: routeNames.staffMessengerLogs.name,
                path: routeNames.staffMessengerLogs.path
            },
            {
                name: routeNames.regionList.name,
                path: routeNames.regionList.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));
        dispatch(getDataForSmsLogFiltersWithBlankTable());
    }, []);
    return (
        <React.Fragment>
            <TopBar titleText="EMS Messenger" />
            <div className="page-container pt-4">
                <ServiceWrapper
                    loading={smsLogsStore.loading}
                    error={smsLogsStore.error}
                    data={smsLogsStore.data}
                    placeholderBlockCount={1}
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                />
            </div>
        </React.Fragment>
    );
}

export default StaffMessengerLogs;
