import React from "react";
import {useAbsenceConfigPage} from "./Hooks/useAbsenceConfigPage";
import EditUserAbsenceConfigForm from "./Components/EditUserAbsenceConfigForm";
import {WithServiceState} from "store-fetch-wrappers";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const ServiceWrapper = WithServiceState(EditUserAbsenceConfigForm);

function AbsenceConfig() {
    const {userConfigStore} = useAbsenceConfigPage();
    return (
        <React.Fragment>
            <TopBar titleText="Annual Leave Configuration" />
            <div className="page-container pt-4">
                <ServiceWrapper
                    showLoadingText={true}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                    {...userConfigStore}
                />
            </div>
        </React.Fragment>
    );
}

export default AbsenceConfig;
