import React from "react";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../../components/Table/NoItemsMessage";
import FormHeader from "../../../../components/Form/FormHeader";
import useYearlyConfigTable from "./Hooks/useYearlyConfigTable";
import EditYearConfigTableAction from "./EditYearConfigTableAction";
import {IconType} from "../../../../components/Icon/Icon";
import ActionButtonConfirmation, {
    IconButton
} from "../../../../components/Button/ActionButtonConfirmation";
import AddYearlyConfig from "./AddYearlyConfig";
import {UserAbsenceConfigYear} from "../../../../api/staff/code";

function YearlyConfigTable(props: UserAbsenceConfigYear[]) {
    const {rows, deleteYearlyConfig} = useYearlyConfigTable(props);
    return (
        <React.Fragment>
            <FormHeader headerName={"Yearly Entitlement"} />
            <AddYearlyConfig />
            <PulseTable
                items={rows}
                headers={{
                    year: "Year",
                    holidayLimit: "Holiday Limit",
                    actions: "Actions"
                }}
                customRenderers={{
                    actions: (item) => {
                        return (
                            <React.Fragment>
                                <div className="row ml-0 ml-0">
                                    <EditYearConfigTableAction
                                        yearConfig={item.actions}
                                        tooltipText={"Edit Year Entitlement"}
                                    />
                                    <ActionButtonConfirmation
                                        action={async () => await deleteYearlyConfig(item.actions)}
                                        modalText={
                                            "Are you sure you want to delete this yearly entitlement?"
                                        }
                                        modalTitle={"Delete Year Entitlement"}
                                        tooltipText={"Delete Year Entitlement"}
                                        icon={IconType.Bin}
                                        iconButton={IconButton.Icon}
                                    />
                                </div>
                            </React.Fragment>
                        );
                    }
                }}
                noItemsSection={
                    <NoItemsMessage
                        message={
                            "Yearly Annual Leave configurations haven't been created for this staff member"
                        }
                    />
                }
            />
        </React.Fragment>
    );
}

export default YearlyConfigTable;
