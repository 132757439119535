import React from "react";
import DefaultConfig from "./DefaultConfig";
import {useEditUserAbsenceConfigForm} from "../Hooks/useEditUserAbsenceConfigForm";
import FormActionContainer from "../../../components/Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../components/Button/MCButton";
import YearlyConfigTable from "./YearConfigTable/YearlyConfigTable";
import {UserAbsenceConfig} from "../../../api/staff/code";

function EditUserAbsenceConfigForm(props: UserAbsenceConfig) {
    const {backToStaffDatabase} = useEditUserAbsenceConfigForm();
    return (
        <React.Fragment>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore */}
            <DefaultConfig {...props["default"]} />
            <YearlyConfigTable {...props.years} />

            <div className="row-modifier">
                <FormActionContainer>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Back to Staff Database"}
                        onClick={backToStaffDatabase}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </FormActionContainer>
            </div>
        </React.Fragment>
    );
}

export default EditUserAbsenceConfigForm;
