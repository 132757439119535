import React from "react";
import {useRegionListContainer} from "./Hooks/useRegionListContainer";
import {WithServiceState} from "store-fetch-wrappers";
import RegionListTable from "./Components/RegionListTable";
import TopBar from "../../Layouts/Layout/Components/TopBar/TopBar";

const ServiceWrapper = WithServiceState(RegionListTable);

function RegionListContainer() {
    const {regionListStore} = useRegionListContainer();
    return (
        <>
            <TopBar titleText="Locations" />
            <div className="page-container pt-4">
                <ServiceWrapper
                    loading={regionListStore.loading}
                    error={regionListStore.error}
                    data={regionListStore.data}
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                />
            </div>
        </>
    );
}

export default RegionListContainer;
