import React, {useEffect} from "react";
import {WithServiceState} from "store-fetch-wrappers";
import StaffListTable from "./Components/StaffListTable";
import {routeNames} from "../../components/Navigation/routeNames";
import {NavigationItem, setNavigationItems} from "../../store/navigation/navigationSlice";
import {useAppDispatch, useAppSelector} from "../../components/Hooks/redux";
import {getAllMedicareStaffMembers} from "../../store/staffList/thunks/thunks";
import {clearStaffListStore} from "../../store/staffList/staffListSlice";
import TopBar from "../Layouts/Layout/Components/TopBar/TopBar";

const StaffListServiceWrapper = WithServiceState(StaffListTable);

const StaffDatabase = () => {
    const dispatch = useAppDispatch();
    const staffListStore = useAppSelector((state) => state.staffList);

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.staffDatabase.name,
                path: routeNames.staffDatabase.path
            },
            {
                name: routeNames.staffMessengerLogs.name,
                path: routeNames.staffMessengerLogs.path
            },
            {
                name: routeNames.regionList.name,
                path: routeNames.regionList.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));

        dispatch(getAllMedicareStaffMembers());

        return function () {
            dispatch(clearStaffListStore());
        };
    }, []);

    return (
        <React.Fragment>
            <TopBar titleText="Staff Management" />
            <div className="page-container pt-4">
                <StaffListServiceWrapper
                    loading={staffListStore.loading}
                    error={staffListStore.error}
                    placeholderBlockCount={1}
                    data={staffListStore.data}
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                />
            </div>
        </React.Fragment>
    );
};

export default StaffDatabase;
